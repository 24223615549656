/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { array } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Typography } from '@creditas-ui/react'

import {
  Author,
  AvatarsContainer,
  Avatar,
  Container,
  ImageContainer,
  LeftContainer,
  ListOpinionsContainer,
  OpinionBody,
  OpinionContainer,
  OpinionsContainer,
  OpinionText,
  RightContainer,
  TitleOpinionsContainer,
  ChartContainer,
  CircularChart,
  CirclePath,
} from './testimonials.styles'

const propTypes = {
  users: array,
  imageAuthors: array,
  avatarAuthors: array,
}

const Testimonials = ({ users, imageAuthors, avatarAuthors }) => {
  const { t } = useTranslation()
  const usersCount = Math.min(
    users.length,
    imageAuthors.length,
    avatarAuthors.length,
  )
  const [currentUser, setCurrentUser] = useState(0)
  const [timer, setTimer] = useState(0)

  useEffect(() => {
    setTimer(
      setInterval(() => setCurrentUser((currentUser + 1) % usersCount), 6000),
    )
    return () => clearInterval(timer)
  }, [currentUser, usersCount])

  const handleClick = index => {
    if (index !== currentUser) {
      clearInterval(timer)
      setCurrentUser(index)
    }
  }

  return (
    <Container>
      <OpinionsContainer>
        <ListOpinionsContainer>
          <OpinionContainer>
            <LeftContainer>
              <ImageContainer
                src={imageAuthors[currentUser]}
                alt={`Author ${currentUser + 1}`}
              />
            </LeftContainer>
            <RightContainer>
              <TitleOpinionsContainer>
                <Typography
                  component="h2"
                  variant="headingLgLight"
                  color="neutral.90"
                  dangerouslySetInnerHTML={{
                    __html: t('commons:testimonials:title'),
                  }}
                />
              </TitleOpinionsContainer>
              <OpinionBody>
                <OpinionText>
                  <Typography color="neutral.80" variant="bodyXlLight">
                    {`"${users[currentUser].description}"`}
                  </Typography>
                </OpinionText>
                <Author>
                  <Typography color="neutral.90" variant="bodyLgBold">
                    {users[currentUser].name}
                  </Typography>
                </Author>
              </OpinionBody>
              <AvatarsContainer>
                {avatarAuthors.map((img, index) => {
                  return (
                    <ChartContainer
                      key={index}
                      onClick={() => handleClick(index)}
                    >
                      {index === currentUser && (
                        <CircularChart viewBox="0 0 36 36">
                          <CirclePath
                            strokeDasharray="100, 100"
                            d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                          />
                        </CircularChart>
                      )}
                      <Avatar
                        src={img}
                        alt={`Avatar ${currentUser + 1}`}
                        size="md"
                        variant="light"
                        active={index === currentUser}
                      />
                    </ChartContainer>
                  )
                })}
              </AvatarsContainer>
            </RightContainer>
          </OpinionContainer>
        </ListOpinionsContainer>
      </OpinionsContainer>
    </Container>
  )
}

Testimonials.propTypes = propTypes

export default Testimonials
